const Home: React.FC = () => {
  return (

      <div className="container mt-4">
       
       <h1>Welcome to Haddaya Admin Console</h1>

    </div>
);
};

export default Home;
